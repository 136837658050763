<template>
    <v-container>
      <!--BOC:[breadcrumb]-->
      <slot name="layoutBreadcrumb"></slot>
      
      <!--EOC-->
      <!--BOC:[title]-->
      <APageTitle title="Tabulator - Developer Documentation" />
      <!--EOC-->
      <v-row>
        <v-col cols="12" sm="3">
          <TheLeftSidebar :leftMenuItems=leftmenu ></TheLeftSidebar>
        </v-col>
  
        <v-col cols="12" sm="9">
          <v-card min-height="70vh" rounded="lg">
            <slot name="loyoutBody"></slot>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  //BOC:[breadcrumb]
  import TheLeftSidebar from "../../components/PageDeveloperTabulator/TheLeftSidebar.vue";
  import leftmenu from "../../config/leftmenu.js";
  //EOC
  import { mapState } from "vuex";
  export default {
    components: {
      TheLeftSidebar
    },
    computed: mapState({
      //
    }),
    props: {
      //
    },
    data: () => ({
      leftmenu: leftmenu,
    }),

    created() {
       //
    },
   
    mounted() {
      //
    },
    methods: {
      //
    },
  };
  </script>