<template>
  <PageDeveloperLayout>
    <template v-slot:layoutBreadcrumb>
      <ABreadcrumb :items="breadcrumb.items" />
    </template>

    <template v-slot:loyoutBody>
      <v-card-title>Product Details for ID {{ columnKey }}</v-card-title>
      <v-card-text>
        
        <p>
          
        </p>
      </v-card-text>
    </template>
  </PageDeveloperLayout>
</template>

<script>
import PageDeveloperLayout from "./layouts/PageDeveloperLayout.vue";
import Breadcrumb from "@/objects/breadcrumb";

export default {
  name: "TheColumnContent",
  components: {
    PageDeveloperLayout,
  },
  props: {
    //
  },
  data: () => ({
    columnKey: "",
    breadcrumb: new Breadcrumb(),
  }),
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Columns",
      to: { name: "" },
      exact: true,
    });
    //EOC

    this.columnKey = this.$route.params.columnKey;
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style scoped>
</style>